import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./components/security/Login";
import Logout from "./components/security/Logout";
import ForgotPassword from "./components/security/ForgotPassword";
import Signup from "./components/security/Signup";
import RequiredAuth from "./components/security/RequiredAuth";
import { ConfigProvider } from "antd";
import { getUser } from "./service/api_service";

import { observer } from "mobx-react-lite";
import {svgLogo} from "./utils/logo";

import { Layout, Menu, Button } from "antd";
import { useCallback, useEffect, useState } from "react";
import Manual from "./components/manual/Manual";
import Project from "./components/automated/Project";
import Account from "./components/account/Account";
import Setting from "./components/account/Setting";
import JobDetail from "./components/manual/JobDetail";
import ProjectDetail from "./components/automated/ProjectDetail";
import LoginCallback from "./components/security/LoginCallback";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}



function App({ authStore, jobStore }) {
  const [wordpress, setWordpress] = useState(false);

  const items = [
    getItem("Projects", "automated"),
    getItem("Manual", "manual"),
  ];

  if(authStore.user && authStore.user.org && authStore.user.org.main_user === authStore.user.user_id) {
    items.push(getItem("Account", "account"));
    items.push(getItem("Settings", "setting"));
  } else {
    items.push(getItem("Settings", "setting"));
  }

  useEffect(() => {
    setWordpress(process.env.REACT_APP_BUILD_TARGET === "wordpress");
  }, []);

  const onOpenChange = (keys) => {
    if (keys.key === "manual") {
      document.location = "/manual";
    } else if (keys.key === "automated") {
      document.location = "/";
    } else if (keys.key === "account") {
      document.location = "/account";
    } else if (keys.key === "setting") {
      document.location = "/setting";
    }
  };

  const [open, setOpen] = useState(false);

  const fetchData = useCallback(async () => {
    const user = await getUser();
    authStore.updateUser(user);
  }, [authStore]);

  useEffect(() => {
    if (authStore.session !== null) {
      fetchData();
    }
  }, [authStore.session, fetchData]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#1b263b",
        },
      }}
    >
      <div className="App">
        <Layout style={{ minHeight: "40vh", background: "white" }}>
          {!wordpress && authStore.authenticated && (
            <Sider
              style={{
                height: "100vh",
              }}
              theme="light"
            >
              <Header className="header">
              <div className="App__logo">
                  <div className="logo">
                  <a href="/">
                  {svgLogo}
                  </a>
                  </div>
                </div>
              </Header>

              <Menu
                mode="inline"
                openKeys={[jobStore.navigation]}
                onClick={onOpenChange}
                defaultSelectedKeys={[jobStore.navigation]}
                selectedKeys={[jobStore.navigation]}
                defaultValue={jobStore.navigation}
                defaultChecked={true}
                style={{
                  width: 210,
                  marginRight: "20px",
                  height: "50vw",
                }}
                items={items}
              />
            </Sider>
          )}
          
          {!wordpress && !authStore.authenticated && (
            <Header className="header">
              <div className="App__logo">
                  <div className="logo">
                  <a href="/">
                  {svgLogo}
                  </a>
                  </div>
                </div>
              <Button
                type="primary"
                style={{ float: "right", margin: "20px" }}
                onClick={() => {
                  document.location = "/signup/FREE";
                }}
              >
                Try for Free
              </Button>
            </Header>
          )}

          <Layout
            className=" content site-layout"
            style={{ background: "white" }}
          >
            <Content
              style={{
                margin: "0px 30px",
                background: "white",
                marginTop: authStore.authenticated ? "70px" : "0px",
              }}
            >
              <BrowserRouter>
                <Routes>
                  <Route
                    path="/manual"
                    element={
                      <RequiredAuth
                        authStore={authStore}
                        children={
                          <Manual authStore={authStore} jobStore={jobStore} />
                        }
                      />
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <RequiredAuth
                        authStore={authStore}
                        children={
                          <Project authStore={authStore} jobStore={jobStore} />
                        }
                      />
                    }
                  />
                  <Route
                    path="/project/:id"
                    element={
                      <RequiredAuth
                        authStore={authStore}
                        children={
                          <ProjectDetail
                            authStore={authStore}
                            jobStore={jobStore}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path="/account"
                    element={
                      <RequiredAuth
                        authStore={authStore}
                        children={
                          <Account authStore={authStore} jobStore={jobStore} />
                        }
                      />
                    }
                  />

                  <Route
                    path="/setting"
                    element={
                      <RequiredAuth
                        authStore={authStore}
                        children={
                          <Setting authStore={authStore} jobStore={jobStore} />
                        }
                      />
                    }
                  />
                  <Route
                    path="/job/:id"
                    element={
                      <RequiredAuth
                        authStore={authStore}
                        children={
                          <JobDetail
                            authStore={authStore}
                            jobStore={jobStore}
                          />
                        }
                      />
                    }
                  />
                  <Route
                    path="/login-callback"
                    element={<LoginCallback  authStore={authStore}></LoginCallback>}
                  />
                  <Route
                    path="/login"
                    element={<Login authStore={authStore} />}
                  />
                  <Route
                    path="/signup/:option"
                    element={<Signup authStore={authStore} />}
                  />
                  <Route
                    path="/logout"
                    element={<Logout authStore={authStore} />}
                  />
                  <Route
                    path="/forgot"
                    element={<ForgotPassword authStore={authStore} />}
                  />
                </Routes>
              </BrowserRouter>
            </Content>
          </Layout>
        </Layout>
      </div>
    </ConfigProvider>
  );
}

export default observer(App);
