import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Spin, Modal, Input } from "antd";
import {
  inviteUser,
  listUsers,
  resendInvitation,
} from "../../service/api_service";
import { Table } from "antd";
import { notification } from "antd";

function Users({ jobStore, authStore }) {
  jobStore.setNavigation("users");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [users, setUsers] = useState([]);
  const [me, setMe] = useState(null);

  const [loading, setLoading] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const resend = async (userId) => {
    await resendInvitation(userId);
    notification.success({ message: "Resent invitation successfully" });
  };

  const fetchData = async () => {
    const users = await listUsers();
    console.log(users);
    setUsers(users);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOk = async () => {
    setConfirmLoading(true);

    try {
      await inviteUser(email);
      setIsModalOpen(false);
      fetchData();
    } catch (e) {
      setError(e.response.data.message);
    }
    setConfirmLoading(false);

    return false;
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "user_id",
      key: "email",
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "action",
      key: "action",
      render: (row) => {
        return row.status === "INVITED" ? (
          <Button onClick={() => resend(row.user_id)}>Resend</Button>
        ) : (
          <div></div>
        );
      },
    },
  ];

  const antIconLarge = (
    <LoadingOutlined
      style={{ fontSize: 24, color: "black", margin: "0 5px" }}
      spin
    />
  );

  return (
    <div className="Users">
      {loading && <Spin indicator={antIconLarge}></Spin>}
      {!loading && (
        <div>
          <div style={{ float: "right" }}>
            <Button type="primary" onClick={() => setIsModalOpen(true)}>
              Invite
            </Button>
          </div>
          <Table dataSource={users} columns={columns} />;
        </div>
      )}

      <Modal
        title="Invite a team member"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        okText="Invite"
      >
        <p>Email</p>
        <Input placeholder="email" onChange={(e) => setEmail(e.target.value)} />
        {error && <div style={{ color: "red" }}>{error}</div>}
      </Modal>
    </div>
  );
}

export default observer(Users);
