import React, {  } from "react";
import { observer } from "mobx-react-lite";
import { Tabs } from 'antd';
import Notifications from "./Notifications";
import Security from "./Security";
import Users from "./Users";

import "./Setting.css";

function Setting({ jobStore, authStore}) {
  jobStore.setNavigation("setting");

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: '1',
      label: 'Notifications',
      children: <Notifications jobStore={jobStore} />
    },
    {
      key: '2',
      label: 'Security',
      children: <Security jobStore={jobStore} authStore={authStore} />
    } 
  ];

  const user = authStore.user;
  if(user && user.user_id && user.org && user.user_id === user.org.main_user) {
    items.push ({
      key: '3',
      label: 'Users',
      children: <Users jobStore={jobStore} authStore={authStore} />
    });
  }

  return (
    <div className="Setting">
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </div>
  );
}

export default observer(Setting);
