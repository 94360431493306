import axios from "axios";
import { AuthStore } from "../store/authStore";
import { userSession } from "../components/security/cognito";
import { config } from "../utils/config";


const URL = config.URL;



const headers = () => {
  return {
    Authorization: `Bearer ${AuthStore.session.getIdToken().getJwtToken()}`,
  };
};

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    let originalConfig = err.config;
    if (err.response.status === 401) {
      if (AuthStore.authenticated && AuthStore.session != null) {
        try {
          originalConfig._retry = true;
          const session = await userSession(AuthStore.session);
          AuthStore.interceptorSetSession(session);
          originalConfig.headers = headers();
          originalConfig.headers["content-type"] = "application/json";
          return axios(originalConfig);
        } catch (e) {
          return Promise.reject(e);
        }
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export async function getUser() {
  return (await axios.get(`${URL}/me`, { headers: headers() })).data;
}




function xApiKey() {
  return {
    headers: {
      "x-api-key": `${config.API_KEY}`
    },
  };
}

export async function createUser(username, subscription, company) {
  const payload = {
    "username": username,
    "subscription": subscription,
    "company": company
  }
  return (await axios.post(`${URL}/user`, payload)).data;
}

export async function updateUser(reportsEnabled) {
  const payload = {
    "reporting_enabled": reportsEnabled,
  }
  return (await axios.put(`${URL}/me`, payload, { headers: headers() })).data;
}


export async function submitMatcher(name, original, final, originalFile, finalFile, mode) {
  const payload = {
    "name": name,
    "train_docs": final,
    "predict_docs": original,
    "train_file": finalFile,
    "predict_file": originalFile,
    "mode": mode
  }
  if (process.env.REACT_APP_BUILD_TARGET === "wordpress")
    return (await axios.post(`${URL}/matcher/train`, payload, xApiKey())).data;
  return (await axios.post(`${URL}/matcher/train`, payload, { headers: headers() })).data;
}

export async function loadJob() {
  const jobs = (await axios.get(`${URL}/job`, { headers: headers() })).data;
  return jobs;
}

export async function deleteJob(jobId) {
  return (await axios.delete(`${URL}/job/${jobId}`, { headers: headers() })).data;
}

export async function loadPlans() {
  const plans = (await axios.get(`${URL}/plan`, {})).data;
  return plans;
}

export async function loadJobDetail(jobId) {
  var jobDetail;
  if (process.env.REACT_APP_BUILD_TARGET === "wordpress") {
    jobDetail = (await axios.get(`${URL}/job/${jobId}`, xApiKey())).data;
  }
  else {
    jobDetail = (await axios.get(`${URL}/job/${jobId}`, { headers: headers() })).data;
  }
  return jobDetail;
}

export async function getUploadURLs() {
  if (process.env.REACT_APP_BUILD_TARGET === "wordpress")
    return (await axios.post(`${URL}/upload`, {}, xApiKey())).data;
  return (await axios.post(`${URL}/upload`, {})).data;
}

export async function loadRoles(planLimits = false) {
  const me = (await axios.get(`${URL}/me?plan_limits=${planLimits}`, { headers: headers() })).data;
  const roles = me['subscription'];
  return { roles, me };
}

export async function setupIntent() {
  const intent = (await axios.post(`${URL}/payment`, {}, { headers: headers() })).data;
  return intent;
}

export async function associateCard(paymentMethodId) {
  const intent = (await axios.post(`${URL}/payment/attach/${paymentMethodId}`, {}, { headers: headers() })).data;
  return intent;
}


export async function subscribe(plan, frequency) {
  const intent = (await axios.post(`${URL}/subscription/${plan}/${frequency}`, {}, { headers: headers() })).data;
  return intent;
}

export async function deletePaymentMethod() {
  return (await axios.delete(`${URL}/payment`, { headers: headers() })).data;
}

export async function getInvoices() {
  const invocies = (await axios.get(`${URL}/payment`, { headers: headers() })).data;
  return invocies;
}

export async function deleteSubscription() {
  return (await axios.delete(`${URL}/subscription`, { headers: headers() })).data;
}




export async function resumeSubscription(plan) {
  return (await axios.post(`${URL}/subscription/${plan}?resume=true`, {}, { headers: headers() })).data;
}


export async function contact(name, email, message, token) {
  const payload = {
    "name": name,
    "email": email,
    "message": message,
    "token": token
  }
  return (await axios.post(`${URL}/contact`, payload)).data;
}

export async function createProject(name, final, key) {
  const payload = {
    "name": name,
    "train_docs": final,
    "mode": "elastic",
    "key": key
  }
  return (await axios.post(`${URL}/matcher/project`, payload, { headers: headers() })).data;
}

export async function createProspectProject() {
  const payload = {}
  return (await axios.post(`${URL}/prospect-project`, payload, { headers: headers() })).data;
}

export async function loadProject() {
  const projects = (await axios.get(`${URL}/project`, { headers: headers() })).data;
  return projects;
}

export async function deleteProject(projectId) {
  return (await axios.delete(`${URL}/project/${projectId}`, { headers: headers() })).data;
}

export async function loadProjectDetail(projectId) {
  var projectDetail;
  if (process.env.REACT_APP_BUILD_TARGET === "wordpress") {
    projectDetail = (await axios.get(`${URL}/project/${projectId}`, xApiKey())).data;
  }
  else {
    projectDetail = (await axios.get(`${URL}/project/${projectId}`, { headers: headers() })).data;
  }
  return projectDetail;
}

export async function getProjectAgg(projectId) {
  return (await axios.get(`${URL}/project/${projectId}/agg`, { headers: headers() })).data;
}

export async function updateProject(projectId, payload) {
  return (await axios.put(`${URL}/project/${projectId}`, payload, { headers: headers() })).data;
}

export async function updateBillingInfo(payload) {
  return (await axios.put(`${URL}/billing`, payload, { headers: headers() })).data;
}

export async function deleteRedirectUrl(projectId, data) {
  return (await axios.post(`${URL}/project/${projectId}/redirect`, { "action": "delete", ...data }, { headers: headers() })).data;
}

export async function updateRedirectUrl(projectId, data) {
  return (await axios.put(`${URL}/project/${projectId}/redirect`, data, { headers: headers() })).data;
}

export async function inviteUser(email) {
  return (await axios.post(`${URL}/auth-user/invite`, {email: email}, { headers: headers() })).data;
}

export async function listUsers() {
  return (await axios.get(`${URL}/auth-user`, { headers: headers() })).data;
}

export async function resendInvitation(userId) {
  return (
    await axios.post(
      `${URL}/auth-user/invite/resend`,
      { user_id: userId },
      { headers: headers() }
    )
  ).data;
}

export async function getProjectRedirectUrls(projectId, limit = 100, page_cursor = null) {
  if (page_cursor === null) {
    page_cursor = "";
  }
  const projects = (await axios.get(`${URL}/project/${projectId}/redirect?limit=${limit}&page_cursor=${page_cursor}`, { headers: headers() })).data;
  return projects;
}

export async function getAllProjectRedirectUrls(projectId) {
  let projects = [];
  let limit = 1000;
  let page_cursor = null;
  if (page_cursor === null) {
    page_cursor = "";
  }
  do {
    const data = (await axios.get(`${URL}/project/${projectId}/redirect?limit=${limit}&page_cursor=${page_cursor}`, { headers: headers() })).data;
    page_cursor = data.page_cursor;
    projects = [...projects, ...data.items];
  } while (page_cursor != null);

  return projects;
}


export async function getProjectRedirectUrlsByDay(projectId, limit = 100, page_cursor = null, start_date = null, end_date = null, strip_url=false) {
  if (page_cursor === null) {
    page_cursor = "";
  }
  if (start_date === null) {
    start_date = "";
  }
  if (end_date === null) {
    end_date = "";
  }
  const projects = (await axios.get(`${URL}/project/${projectId}/redirect-history-day?limit=${limit}&start_date=${start_date}&end_date=${end_date}&page_cursor=${page_cursor}&strip_url=${strip_url}`, { headers: headers() })).data;
  return projects;
}


export async function listUsersForSharedProject(projectId) {
  return (
    await axios.get(`${URL}/project/${projectId}/share`, { headers: headers() })
  ).data;
}

export async function shareProject(projectId, userIds) {
  return (
    await axios.put(
      `${URL}/project/${projectId}/share`,
      { user_ids: userIds },
      { headers: headers() }
    )
  ).data;
}

export async function unshareProject(projectId, userId) {
  return (
    await axios.delete(
      `${URL}/project/${projectId}/share`,
      { user_id: userId },
      { headers: headers() }
    )
  ).data;
}
