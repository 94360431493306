import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PauseCircleTwoTone,
  PlayCircleTwoTone,
  ShareAltOutlined,
} from "@ant-design/icons";
import {
  Table,
  Spin,
  Modal,
  Button,
  Input,
  Checkbox,
  Form,
  Col,
  Row,
  notification,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  deleteProject,
  loadProject,
  createProject,
  createProspectProject,
  updateProject,
  listUsers,
  listUsersForSharedProject,
  shareProject,
  unshareProject,
} from "../../service/api_service";
import { Select, Space } from "antd";

import "./Project.css";
const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: "white" }} spin />
);
const antIconBlack = (
  <LoadingOutlined style={{ fontSize: 18, color: "black" }} spin />
);

const { confirm } = Modal;

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function Project({ jobStore }) {
  jobStore.setNavigation("automated");

  const [loading, setLoading] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const navigate = useNavigate();

  const [deleting, setDeleting] = useState([]);
  const [playPause, setPlayPause] = useState([]);

  const [visible, setVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [sitemap, setSitemap] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmEnabled, setUtmEnabled] = useState(true);
  const [emailReport, setEmailReport] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [editSubmitting, setEditSubmitting] = useState(false);
  const [editData, setEditData] = useState({});

  const [codeVisible, setCodeVisible] = useState(false);
  const [shareProjectLoading, setShareProjectLoading] = useState(false);
  const [shareProjectDialog, setShareProjectDialog] = useState(false);
  const [shareProjectId, setShareProjectId] = useState();
  const [createdProjectId, setCreatedProjectId] = useState("");

  const [selectedProjectValues, setSelectProjectValues] = useState([]);
  const [sharedProjectOptions, setSharedProjectOption] = useState([]);
  const [prospectProjectKey, setProspectProjectKey] = useState("");

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  const handleShareClick = async (projectId) => {
    //setSelectProjectValues
    setShareProjectLoading(true);
    setShareProjectDialog(true);
    setShareProjectId(projectId);
    const users = await listUsersForSharedProject(projectId);
    setSelectProjectValues(users.map((x) => x.user_id));
    setShareProjectLoading(false);
  };

  const handleChange = (value) => {
    shareProject(shareProjectId, value);
    setSelectProjectValues(value);
  };

  const onFinish = async () => {
    setSubmitting(true);
    const createdProject = await createProject(
      name,
      sitemap.split("\n"),
      prospectProjectKey
    );
    const projects = await loadProject();
    setProjectList(projects);
    setVisible(false);
    setSubmitting(false);

    // setCodeVisible(true);
    notification.success({ message: "Project created successfully" });

    setCreatedProjectId(createdProject["project_id"]);
  };

  const onEditFinish = async () => {
    setEditSubmitting(true);
    await updateProject(editData.project_id, {
      sitemap_xml: sitemap,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_enabled: utmEnabled,
      email_report: emailReport,
    });
    const projects = await loadProject();
    setProjectList(projects);
    setShowEditForm(false);
    setEditSubmitting(false);
    setSitemap("");
  };

  const showEditFormModal = (record) => {
    console.log(record);
    setShowEditForm(true);
    setEditData(record);
    if (!record.hasOwnProperty("utm_enabled")) {
      record["utm_enabled"] = true;
    }
    editForm.setFieldsValue({
      sitemapUrl: record?.sitemap_xml,
      utm_source: record?.utm_source,
      utm_medium: record?.utm_medium,
      utm_enabled: record?.utm_enabled,
    });
    setSitemap(record?.sitemap_xml);
    setUtmSource(record?.utm_source);
    setUtmMedium(record?.utm_medium);
    setUtmEnabled(record?.utm_enabled);
    setEmailReport(record?.email_report);
  };

  const onCreateProject = async () => {
    form.resetFields();
    setVisible(true);
    setName("");
    setSitemap("");
    const prospect = await createProspectProject();
    setProspectProjectKey(prospect.prospect_project_id);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (_, record) => {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={{ color: "#343434" }}
            onClick={() => navigate(`/project/${record.project_id}`)}
          >
            {record.name}
          </a>
        );
      },
    },
    {
      title: "~30 Days",
      dataIndex: "redirects_month",
      key: "redirects_month",
      render: (_, record) => {
        let redirect = 0;
        if (record.redirect_count_month) {
          redirect = record.redirect_count_month;
        }
        return <span>{numberWithCommas(redirect)} redirect/s</span>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "40px",
      render: (_, record) => {
        const playPauseValue = record?.pause ? record.pause : false;
        return (
          <div style={{ display: "flex" }}>
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key="share"
                className="Jobs__action"
                onClick={() => {
                  handleShareClick(record.project_id);
                }}
              >
                <ShareAltOutlined />
              </a>
            }

            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key="playPause"
                className="Jobs__action"
                onClick={() =>
                  togglePlayPause(record.project_id, !playPauseValue)
                }
              >
                {playPause.indexOf(record.project_id) >= 0 ? (
                  <Spin indicator={antIconBlack}></Spin>
                ) : playPauseValue ? (
                  <PlayCircleTwoTone twoToneColor="#7FB367" />
                ) : (
                  <PauseCircleTwoTone twoToneColor="#FFC806" />
                )}
              </a>
            }
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key="delete"
                className="Jobs__action"
                onClick={() =>
                  showDeleteConfirm(record.project_id, record.name)
                }
              >
                {deleting.indexOf(record.project_id) >= 0 ? (
                  <Spin indicator={antIconBlack}></Spin>
                ) : (
                  <DeleteOutlined />
                )}
              </a>
            }
            {
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key="edit"
                className="Jobs__action"
                onClick={() => showEditFormModal(record)}
              >
                <EditOutlined />
              </a>
            }
          </div>
        );
      },
    },
  ];

  const showDeleteConfirm = (projectId, name) => {
    const title = `Are you sure you want to delete project "${name}"`;
    confirm({
      title: title,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      okType: "danger",
      cancelText: "cancel",
      onOk() {
        performDeletion(projectId);
      },
      onCancel() {},
    });
  };

  const performDeletion = async (projectId) => {
    setDeleting([...deleting, projectId]);
    try {
      await deleteProject(projectId);
      const projects = await loadProject();
      setProjectList(projects);
    } finally {
      setDeleting(deleting.splice(deleting.indexOf(projectId), 1));
    }
  };

  const togglePlayPause = async (projectId, value) => {
    setPlayPause([...playPause, projectId]);
    try {
      await updateProject(projectId, { project_pause: value });
      const projects = await loadProject();
      setProjectList(projects);
    } finally {
      setPlayPause(playPause.splice(playPause.indexOf(projectId), 1));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const projects = await loadProject();
      setProjectList(projects);

      const users = await listUsers();

      setSharedProjectOption(
        users.map((x) => {
          return {
            label: x.user_id,
            value: x.user_id,
          };
        })
      );

      setLoading(false);
    };

    fetchData();
  }, []);

  const script = () => {
    const src = `${window.location.origin}/dist/app.bundle.js`;
    // eslint-disable-next-line no-useless-escape
    const script = `<script src="${src}" class="${prospectProjectKey}" id="redirectMapperID"><\/script>`;
    return script;
  };

  return (
    <div className="Project">
      <Modal
        open={shareProjectDialog}
        title={"Share project"}
        onCancel={() => {
          setShareProjectDialog(false);
          setShareProjectId(null);
        }}
        footer={null}
      >
        <div style={{ marginBottom: "10px" }}>
          {shareProjectLoading ? (
            <Spin indicator={antIconBlack}></Spin>
          ) : (
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Please select"
              value={selectedProjectValues}
              onChange={handleChange}
              options={sharedProjectOptions}
            />
          )}
        </div>
      </Modal>

      <Modal
        open={codeVisible}
        title={"Project created successfully"}
        onCancel={() => {
          setCodeVisible(false);
        }}
        footer={null}
      >
        <div style={{ marginBottom: "10px" }}>
          Paste this code in the &lt;head&gt; section of your 404 Page Template
        </div>
        <div style={{ border: "1px solid #9a9a9a", padding: "20px" }}>
          <code>{script()}</code>
        </div>
      </Modal>

      <Modal
        open={visible}
        title={"Create a new project"}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFinish={onFinish}
        >
          <Form.Item
            className="AddProject__item"
            placeholder="Name"
            name="name"
            style={{ marginBottom: "40px" }}
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input
              placeholder="Enter Project Name"
              onChange={(e) => setName(e.target.value)}
            />
          </Form.Item>

          <Form.Item
            className="AddProject__item"
            placeholder="Paste your Website XML Sitemap URL(s), e.g.

            https://yoursite.com/sitemap_collections.xml
            https://yoursite.com/sitemap_products.xml
            https://yoursite.com/sitemap_pages.xml"
            name="sitemapUrl"
            rules={[
              {
                required: true,
                message: "Please enter XML sitemap URL or final urls",
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              placeholder="Paste your Website XML Sitemap URL(s), e.g.&#10;
              https://yoursite.com/sitemap_collections.xml&#10;
              https://yoursite.com/sitemap_products.xml&#10;
              https://yoursite.com/sitemap_pages.xml"
              onChange={(e) => setSitemap(e.target.value)}
            ></Input.TextArea>
          </Form.Item>

          <div style={{ marginBottom: "10px" }}>
            Paste this code in the &lt;head&gt; section of your 404 Page
            Template
          </div>
          <div
            style={{
              border: "1px solid #9a9a9a",
              padding: "20px",
              marginBottom: "20px",
            }}
          >
            <code>{script()}</code>
          </div>

          <Form.Item style={{ marginBottom: "0" }}>
            <div style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && <span> Create</span>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={showEditForm}
        title={"Update project"}
        onCancel={() => {
          setShowEditForm(false);
        }}
        footer={null}
      >
        <Form
          name="basic"
          initialValues={{ remember: true }}
          autoComplete="off"
          layout="vertical"
          form={editForm}
          onFinish={onEditFinish}
        >
          <Form.Item
            className="AddProject__item"
            placeholder="Enter XML Sitemap URL or Paste Final URLs"
            name="sitemapUrl"
            rules={[
              {
                required: true,
                message: "Please enter XML sitemap URL or final urls",
              },
            ]}
          >
            <Input.TextArea
              rows={5}
              placeholder="XML Sitemap URL not found but you can add new one if you would like to."
              onChange={(e) => setSitemap(e.target.value)}
            ></Input.TextArea>
          </Form.Item>

          <Row>
            <Col span={12}>
              <Form.Item
                placeholder="UTM Enabled"
                name="utm_enabled"
                rules={[
                  {
                    required: false,
                    message: "Enable UTM",
                  },
                ]}
              >
                <Checkbox
                  style={{ margin: "5px", width: "90%" }}
                  placeholder="UTM Enabled"
                  name="utm_enabled"
                  checked={utmEnabled}
                  onChange={(e) => setUtmEnabled(e.target.checked)}
                >
                  UTM Enabled
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                placeholder="Send weekly project reports"
                name="email_report"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Checkbox
                  style={{ margin: "5px", width: "90%" }}
                  placeholder="Send weekly project reports"
                  name="email_report"
                  checked={emailReport}
                  onChange={(e) => setEmailReport(e.target.checked)}
                >
                  Send weekly project reports
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                placeholder="UTM Source"
                name="utm_source"
                rules={[
                  {
                    required: false,
                    message: "redirect utm source",
                  },
                ]}
              >
                <Input
                  style={{ margin: "5px", width: "90%" }}
                  placeholder="UTM Source"
                  onChange={(e) => setUtmSource(e.target.value)}
                  disabled={!utmEnabled}
                ></Input>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                placeholder="UTM Medium"
                name="utm_medium"
                rules={[
                  {
                    required: false,
                    message: "redirect utm medium",
                  },
                ]}
              >
                <Input
                  style={{ margin: "5px", width: "90%" }}
                  placeholder="UTM Medium"
                  onChange={(e) => setUtmMedium(e.target.value)}
                  disabled={!utmEnabled}
                ></Input>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item style={{ marginBottom: "0" }}>
            <div style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit">
                {submitting && <Spin indicator={antIcon} />}
                {!submitting && <span> Update</span>}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      <Button
        size={"large"}
        type="primary"
        style={{ float: "right" }}
        onClick={onCreateProject}
      >
        Create new Project
      </Button>
      <h2>Projects</h2>
      {!loading && projectList.length > 0 && (
        <Table
          columns={columns}
          showHeader={true}
          pagination={true}
          dataSource={projectList}
          rowKey="project"
        ></Table>
      )}

      {!loading && projectList.length === 0 && (
        <p>No project has been created yet.</p>
      )}
      {loading && <Spin indicator={antIconBlack}></Spin>}
    </div>
  );
}

export default observer(Project);
